import React from "react";
import "./Itemsloader.css";
function Itemsloader() {
  return (
    <>
      <div className="cs-loader">
        <div className="cs-loader-inner">
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
        </div>
      </div>
    </>
  );
}

export default Itemsloader;
